import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { NOTIF_PREFS, EMAIL, SAVE_AND_CONTINUE } from "../../constants/OnboardingTranslation";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import Form from "react-bootstrap/Form";

import Sallie from "../Sallie";
import OnboardingStepHeader from "./OnboardingStepHeader";
import SubmitButton from "../StyledComponents/SubmitButton";
import NotificationSchema from "./validations/notificationSchema";
import { postTrackingPrefs } from "../../api/TBIRequests";

import { format } from "date-fns";
import TimeInput from "../TimeInput";
import WeekInput from "../WeekInput";
import stringifyDayOfWeek from "../../lib/stringifyDayOfWeek";
import stringifyWeekOfMonth from "../../lib/stringifyWeekOfMonth";
import customizeMessages from "../../lib/customizeMessages";

const NotificationPrefs = ({ user, setOnboardingPercent }) => {
  const { patient } = useContext(PatientContext);
  const [submitting, setSubmitting] = useState(false);
  const [isEmailAlert, setIsEmailAlert] = useState(true);
  const [timezone, setTimezone] = useState("America/New_York");
  const { language } = useContext(PreferredLanguageContext);
  const history = useHistory();
  useEffect(() => {
    setOnboardingPercent(80);
  }, []);

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone) {
      setTimezone(timezone);
    } else {
      //Intl object doesn't support IE brower, so it returns undefined.
      setTimezone("America/New_York");
    }
  }, []);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      hour: "04",
      partOfDay: "PM",
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
      firstWeek: true,
      secondWeek: true,
      thirdWeek: true,
      fourthWeek: true,
      fifthWeek: true,
    },
    resolver: yupResolver(NotificationSchema),
  });

  const disableDayOfWeek = (e) => {
    if (e.target.checked) {
      setIsEmailAlert(true);
    } else {
      setIsEmailAlert(false);
    }
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    let notificationData = { emailAlert: data.emailAlert };
    let time;
    if (!data.emailAlert) {
      time = null;
    } else {
      time = format(
        new Date(
          0,
          0,
          0,
          data.partOfDay === "AM" ? data.hour : data.hour + 12,
          0),
        "HH:mm");
    }


    notificationData.timeAlert = time;

    const dayOfWeek = stringifyDayOfWeek(data);
    const weekOfMonth = stringifyWeekOfMonth(data);
    notificationData.dayOfWeek = dayOfWeek;
    notificationData.weekOfMonth = weekOfMonth;
    notificationData.tz = timezone;
    notificationData.patientId = patient.patientId;

    console.log('Patient ID: '+ patient.patientId)

    postTrackingPrefs({ data: notificationData })
      .then(() => history.push("/Verification/EmailSent"))
      .catch(() => history.push("/oops"));
  };

  const getPossibleMessages = () => ({
    mainHeader: {
      Stroke: NOTIF_PREFS[language].header,
      tbiPatient: NOTIF_PREFS[language].header,
      caregiver: NOTIF_PREFS[language].header
    },
    sallieText: {
      Stroke: NOTIF_PREFS[language].sallie,
      tbiPatient: NOTIF_PREFS[language].sallie,
      caregiver: NOTIF_PREFS[language].sallie
    },
  });

  const getMessage = customizeMessages({ getPossibleMessages, user })

  return (
    <>
      <OnboardingStepHeader text={getMessage("mainHeader")} />
      <Sallie text={getMessage("sallieText")} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Label
          as="h6"
          style={{ width: "100%", margin: "10px 0px 20px 0px" }}
        >
          {NOTIF_PREFS[language].dashboard}
        </Form.Label>
        <Form.Row>
          {/* <Form.Group controlId="text">
          <Form.Check
            name="textAlert"
            ref={register}
            type="checkbox"
            label="Text Message"
          />
        </Form.Group> */}
          <Form.Group controlId="email">
            <Form.Check
              name="emailAlert"
              ref={register}
              type="checkbox"
              label={EMAIL[language]}
              defaultChecked
              onChange={disableDayOfWeek}
            />
          </Form.Group>
        </Form.Row>

        <WeekInput
          errors={errors}
          register={register}
          label={NOTIF_PREFS[language].reminder}
          disableStatus={!isEmailAlert}
        />

        <TimeInput
          errors={errors}
          register={register}
          label={NOTIF_PREFS[language].day_of_week.label}
          disableStatus={!isEmailAlert}
        />

        <SubmitButton disabled={submitting} type="submit">
          {SAVE_AND_CONTINUE[language]} &gt;
        </SubmitButton>
      </Form>
    </>
  );
};

export default NotificationPrefs;
