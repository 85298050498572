/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../../lib/main-context";
import { DAILY_TRIGGER, SALLIE_TRIGGER, SAVE_AND_CONTINUE, TRIGGER_SELECTOR, TRIGGER_LIST, NONE } from "../../../constants/OnboardingTranslation";

import { Row, Col, Form, Button } from "react-bootstrap";

import { getTBISymptoms, submitFollowUpFactors } from "../../../api/TBIRequests";

import useListSelector from "../../../lib/useListSelector";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import MedicalListSelector from "../../MedicalListSelector";

import { useHistory } from "react-router-dom";

const FactorSelector1 = (setSelectedData) => {
    const { patient } = useContext(PatientContext);
    const { language } = useContext(PreferredLanguageContext);

    const [loading, setLoading] = useState(true);
    const [returnedData, setReturnedData] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [noTrigger, setNoTrigger] = useState(false);

    const {
        toggleFromSelectedList,
        switchSingleSelection,
        addOther,
        selectedData,
        initialize,
    } = useListSelector({
        setInitialData: setReturnedData,
        initialData: returnedData,
    });

    const categories = ["wellness", "travel", "screen time", "environmental", "Dietary", "situational"];

    const colors = {
        wellness: "#dab7ec",
        travel: "#ffdadb",
        "screen time": "#ffdcaf",
        environmental: "#fff3c4",
        Dietary: "#cef3c4",
        situational: "#c1fdf7",
        Other: "#f4f3ee",
    };

    const createItemListFromRes = (res) => {
        const itemList = categories.map((category) => {
            const items = res.data
                .filter((item) => item.subcategory === category)
                .map((item) => item.factor);
            return { category, items };
        });
        itemList.push({ category: "Other", items: [] });
        return itemList;
    };

    useEffect(() => {
        getTBISymptoms()
            .then((res) => {
                const itemList = createItemListFromRes(res);
                for (let i = 0; i < itemList.length; i++) {
                    itemList[i].items.push("None");
                }

                const translatedItemList = itemList.map(itemList => {
                    const { category, items } = itemList;
                    // Translate each item in the items array
                    const translatedItems = items.map(item =>
                        TRIGGER_LIST[language][category][item]
                    );
                    return { category, items: translatedItems };
                });

                setReturnedData(translatedItemList);
                initialize(itemList);
                setLoading(false);
            });
    }, [language]);

    useEffect(() => {
        for (let category in selectedData) {
            if (selectedData[category].includes("None") || selectedData[category].includes("Ninguno")) {
                const selectedArrayWithoutNone = [...selectedData[category]]
                selectedArrayWithoutNone.splice(selectedArrayWithoutNone.indexOf(NONE[language]));
                selectedArrayWithoutNone.forEach((item) => {
                    toggleFromSelectedList(item, selectedArrayWithoutNone, category);
                })
            }
        }
    }, [selectedData]);

    const getPossibleMessages = ({ }) => ({
        mainHeader: {
            Stroke: DAILY_TRIGGER[language].header,
            tbiPatient: DAILY_TRIGGER[language].header,
            caregiver: DAILY_TRIGGER[language].header,
        },
        sallieText: {
            Stroke: SALLIE_TRIGGER[language].patient,
            tbiPatient: SALLIE_TRIGGER[language].patient,
            caregiver: SALLIE_TRIGGER[language].caregiver,
        },
    });

    const messages = {
        wellness: TRIGGER_SELECTOR[language].messages.wellness,
        travel: TRIGGER_SELECTOR[language].messages.travel,
        "screen time": TRIGGER_SELECTOR[language].messages.screen_time,
        environmental: TRIGGER_SELECTOR[language].messages.environmental,
        Dietary: TRIGGER_SELECTOR[language].messages.dietary,
        situational: TRIGGER_SELECTOR[language].messages.situational,
        Other: TRIGGER_SELECTOR[language].messages.other,
    };

    const tabs = {
        wellness: TRIGGER_SELECTOR[language].tabs.wellness,
        travel: TRIGGER_SELECTOR[language].tabs.travel,
        "screen time": TRIGGER_SELECTOR[language].tabs.screen_time,
        environmental: TRIGGER_SELECTOR[language].tabs.environmental,
        Dietary: TRIGGER_SELECTOR[language].tabs.dietary,
        situational: TRIGGER_SELECTOR[language].tabs.situational,
        Other: TRIGGER_SELECTOR[language].tabs.other,
    };

    const history = useHistory();

    const onSubmit = async () => {
        const patientId = patient.patientId;
        const data = selectedData;
        console.log(data);
        try {
            const res = await submitFollowUpFactors({ data, patientId });
        } catch (error) {
            console.log(error);
        } finally {
            history.push("/")
        }
    }

    return (
        <>
            {loading ? (
                "Loading..."
            ) : (
                <>
                    <Row className="justify-content-between" style={{ marginTop: "1rem" }}>
                        <Col
                            sm={8}
                        >
                            <h5 style={{ textAlign: "center" }}>{TRIGGER_SELECTOR[language].category_header}</h5>
                        </Col>
                        <Col
                            sm={4}
                        >
                            <h5 style={{ textAlign: "center" }} >{TRIGGER_SELECTOR[language].list_header}</h5>
                        </Col>
                    </Row>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="wellness">
                        <Row style={{ marginTop: "0.5rem" }}>
                            <Col sm={3} >
                                <Nav fill justify variant="tabs" className="flex-column">
                                    {returnedData.map((itemList, i) => (
                                        <Nav.Item
                                        >
                                            <Nav.Link eventKey={itemList.category}
                                                style={{
                                                    background: `${colors[itemList.category]}`,
                                                    border: '1px solid #106995',
                                                }}>{tabs[itemList.category]}</Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </Col>
                            <Col sm={5}>
                                <Tab.Content>
                                    {returnedData.map((itemList, i) => (
                                        <Tab.Pane eventKey={itemList.category}
                                        >
                                            <MedicalListSelector
                                                key={itemList.category}
                                                patient={patient}
                                                category={itemList.category}
                                                message={messages[itemList.category]}
                                                list={itemList.items}
                                                index={i}
                                                selectedList={selectedData[itemList.category]}
                                                toggleFromSelectedList={toggleFromSelectedList}
                                                selectOne={switchSingleSelection}
                                                addOther={addOther}
                                                noOther={false}
                                            />
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </Col>
                            <Col sm={4}>
                                <div className="justify-content-left" style={{
                                    height: "30rem",
                                    overflow: 'scroll',
                                    color: 'black',
                                    border: '1px solid #106995',
                                    textAlign: "left",
                                    background: "#fff",
                                    padding: "2rem",
                                    borderRadius: "5px",
                                    margin: ".25rem 0",
                                }}>
                                    {Object.entries(selectedData).flatMap(([category, itemList]) => {
                                        return itemList.map((item) => (
                                            <div style={{ margin: '0.5rem 0' }}>
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                    <title>icon/action/check_ccircle_24px</title>
                                                    <defs>
                                                        <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z" id="path-1"></path>
                                                    </defs>
                                                    <g id="icon/action/check_ccircle_24px" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <mask id="mask-2" fill="white">
                                                            <use xlinkHref="#path-1"></use>
                                                        </mask>
                                                        <use id="-↳Color" fill={colors[category]} xlinkHref="#path-1"></use>
                                                    </g>
                                                </svg>
                                                {" "}
                                                {item}
                                            </div>));
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </Tab.Container>

                    <Row>
                        <Col>

                            {noTrigger && (
                                <Form.Control.Feedback
                                    style={{ display: "block" }}
                                    type="invalid"
                                >
                                    {TRIGGER_SELECTOR[language].error}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                        <Button className='mt-2' variant='outline-primary' type='button' onClick={onSubmit}>
                            Submit &gt;
                        </Button>
                    </Row>
                </>
            )}
        </>
    );
};

export default FactorSelector1;
