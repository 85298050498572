import React, { useContext } from "react";
import { PreferredLanguageContext } from '../../lib/main-context';
import { NAVBAR } from "../../constants/HeaderTranslation";

// bootstrap
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

// routing
import { Link, useLocation } from "react-router-dom";

// component
import OnboardingProgress from "./OnboardingProgress";


const shouldRenderCaseReport = (atPatientControlPage, user) => {
  return (
    !atPatientControlPage &&
    (user.userType === "Therapist" || user.userType === "provider")
  );
};

const authenticatedOptions = (atPatientControlPage, user, language) => (
  <>
    {!atPatientControlPage && (
      <Nav.Link eventKey="/home" as={Link} to="/">
        {NAVBAR[language].home}
      </Nav.Link>
    )}
    <Nav.Link eventKey="/media" as={Link} to="/media">
      {NAVBAR[language].media}
    </Nav.Link>
    {!atPatientControlPage && (
      <Nav.Link eventKey="/charts" as={Link} to="/charts">
        {NAVBAR[language].report}
      </Nav.Link>
    )}
    {!atPatientControlPage &&
      <Nav.Link eventKey="/followupform" as={Link} to="/followupform">
        Follow Up Form
      </Nav.Link>
    }
    {shouldRenderCaseReport(atPatientControlPage, user) && (
      <Nav.Link eventKey="/caseReport" as={Link} to="/caseReport">
        {NAVBAR[language].case_report}
      </Nav.Link>
    )}
  </>
);
const userDropDownItems = (userType, language) => (
  <>
    <NavDropdown.Item as={Link} to="/signOut">
      {NAVBAR[language].logout}
    </NavDropdown.Item>
    {userType != "provider" && (
      <NavDropdown.Item as={Link} to="/accountSetting">
        {NAVBAR[language].account_settings}
      </NavDropdown.Item>
    )}
    {(userType == "provider" || userType == "Therapist") && (
      <NavDropdown.Item as={Link} to="/PaymentPage">
        {NAVBAR[language].membership_payments}
      </NavDropdown.Item>
    )}
  </>
);
const unauthenticatedOptions = (language) => (
  <>
    <Nav.Link eventKey="/register" as={Link} to="/register">
      {NAVBAR[language].register}
    </Nav.Link>
    <Nav.Link eventKey="/signIn" as={Link} to="/signIn">
      {NAVBAR[language].signin}
    </Nav.Link>
  </>
);

const Header = ({ user, routeGroup, onboardingPercent }) => {
  const { language } = useContext(PreferredLanguageContext);

  // used to keep the current link active
  const location = useLocation();

  if (routeGroup === "onboarding" || routeGroup === "laterSymptoms")
    return (
      <>
        <Navbar
          style={{ marginBottom: "50px" }}
          id="nav-border"
          collapseOnSelect
          expand="md"
        >
          <Nav style={{ width: "100%" }}>
            <Nav.Item
              onboardingPercent={onboardingPercent}
              as={OnboardingProgress}
            />
          </Nav>
        </Navbar>
        <div className="d-flex justify-content-end">
          <Nav.Link
            style={{ marginTop: "-40px" }}
            eventKey="/logout"
            as={Link}
            to="/signOut"
          >
            {NAVBAR[language].logout}
          </Nav.Link>
          <Nav.Link
            style={{ marginTop: "-40px" }}
            eventKey="/help"
            as={Link}
            to="/help"
          >
            {NAVBAR[language].help}
          </Nav.Link>
        </div>
      </>
    );
  return (
    <>
      <Navbar
        style={{ marginBottom: "50px" }}
        id="nav-border"
        collapseOnSelect
        expand="md"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" style={{ width: "100%" }}>
          {routeGroup !== "onboarding" && (
            <>
              <Nav
                activeKey={location.pathname.slice(
                  location.pathname.lastIndexOf("/")
                )}
                className="mr-auto"
                style={{ fontWeight: "bold", fontSize: "120%" }}
              >
                {user
                  ? authenticatedOptions(
                    location.pathname == "/patientControl",
                    user,
                    language
                  )
                  : unauthenticatedOptions(language)}
                <Nav.Link eventKey="/help" as={Link} to="/help">
                  {NAVBAR[language].help}
                </Nav.Link>
              </Nav>
              <Nav
                activeKey={location.pathname.slice(
                  location.pathname.lastIndexOf("/")
                )}
                className="ml-auto"
                style={{ fontWeight: "bold", fontSize: "120%" }}
              >
                {user ? (
                  <NavDropdown
                    id="user-selection"
                    title={`${user.firstName} ${user.lastName.slice(0, 1)}.`}
                  >
                    {userDropDownItems(user.userType, language)}
                  </NavDropdown>
                ) : (
                  ""
                )}
              </Nav>
            </>
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
export default Header;
