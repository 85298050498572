import React, { useEffect, useState } from "react";
import MedicationHistory from "./MedicationHistory";

import { getHistoryMeds, updateHistoryMeds } from "../../../api/TBIRequests";

const MedicationHistoryContainer = ({ patientId }) => {
    const [loading, setLoading] = useState(true);
    const [medications, setMedications] = useState([]);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    // Fetch medication history to prepopulate the medication field
    useEffect(() => {
        const fetchMedicalHistory = async () => {
            try {
                const res = await getHistoryMeds({ patientId });
                if (res.data.length > 0) {
                    const medicationsData = res.data[0]?.medications || [];
                    setMedications(medicationsData.map((med) => ({
                        medication: med.medication,
                        curUsing: med.curUsing,
                        tolerance: med.tolerance
                    })));
                }
            } catch (error) {
                console.error("Failed to fetch history medications:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchMedicalHistory();
    }, []);

    const handleAddMedication = () => {
        setMedications([...medications, { medication: '', curUsing: '', tolerance: '' }]);
        setUnsavedChanges(true);
    };

    const handleDeleteMedication = (index) => {
        setMedications(medications.filter((_, i) => i !== index));
        setUnsavedChanges(true);
    };

    const handleChange = (event, index, field) => {
        const updatedMedications = [...medications];
        updatedMedications[index][field] = event.target.value;
        setMedications(updatedMedications);
        setUnsavedChanges(true);
    };

    const handleSave = async () => {
        setUnsavedChanges(false);
        const data = medications;
        updateHistoryMeds({ data, patientId });
    }

    if (loading) { return <div className='mb-2 mt-n2'>Loading medications...</div> }
    return (
        <MedicationHistory
            medications={medications}
            onAddMedication={handleAddMedication}
            onDeleteMedication={handleDeleteMedication}
            onChange={handleChange}
            unsavedChanges={unsavedChanges}
            onSubmit={handleSave}
        />)
}

export default MedicationHistoryContainer;