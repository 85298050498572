import React, { useState, useContext, useEffect } from "react";
import "./FollowUpForm.scss";

import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import followUpFormSchema from "../validations/followUpFormSchema";

import { PatientContext } from "../../../lib/main-context";

import { submitFollowUpForm } from "../../../api/TBIRequests";

import Sallie from "../../Sallie";
import OnboardingStepHeader from "../../Onboarding/OnboardingStepHeader";
import MedicationHistoryContainer from "../../StyledComponents/MedicationHistory/MedicationHistoryContainer";
import FactorSelector1 from "../../StyledComponents/FactorSelector/FactorSelector1";

const FollowUpForm = () => {
    const { patient } = useContext(PatientContext);

    const { register, handleSubmit, trigger, errors } = useForm({
        resolver: yupResolver(followUpFormSchema),
    });

    const handleContinue = async (data) => {
        const isValid = await trigger();
        if (isValid) {
            setShowFactorSelector(true);
        }
        console.log(data);
    };

    const handleBack = () => {
        setShowFactorSelector(false);
    }

    const onSubmit = async (data) => {
        const patientId = patient.patientId;
        try {
            const res = await submitFollowUpForm({ data, patientId });
        } catch (error) {
            console.log(error);
        } finally {
            setShowFactorSelector(true);
        }
    }

    const [showMedicalHistory, setShowMedicalHistory] = useState(false);
    const [showDescribeMedicationChange, setShowDescribeMedicationChange] = useState(false);
    const [showPrimitiveReflexFrequency, setShowPrimitiveReflexFrequency] = useState(false);
    const [primitiveReflexDailyOtherCount, setPrimitiveReflexDailyOtherCount] = useState('');

    const hemisphericSensoryStimulationOptions =
        ['Light', 'Sound', 'Smell', 'Spinning', 'OPK', 'Tactile', 'Eyelights', 'Tens']

    const [showHemisphericSensoryQuestions, setShowHemisphericSensoryQuestions] = useState(false);
    const [hemisphericSensoryStimulationOther, setHemisphericSensoryStimulationOther] = useState('');
    const [hemisphericSensoryDailyOtherCount, setHemisphericSensoryDailyOtherCount] = useState('');

    const [showDescribeSpecialDiet, setShowDescribeSpecialDiet] = useState(false);
    const [showDescribeFunctionLoss, setShowDescribeFunctionLoss] = useState(false);
    const [showDescribeSkills, setShowDescribeSkills] = useState(false);

    const [showFactorSelector, setShowFactorSelector] = useState(false);

    let sallieMessage;
    if (showFactorSelector) {
        sallieMessage = `
        It's important to know what you do every day. 
        The more I know about your DAILY ACTIVITIES, 
        the better I become trained to help you. 
        Please check the boxes for what you did recently.`;
    } else {
        sallieMessage = "Please complete this at home follow up form.";
    }

    return (
        <div className="followUpFormContainer">
            <div className="header">
                <OnboardingStepHeader text="At Home Follow Up Form" />
                <Sallie text={sallieMessage} />
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='followUpForm' style={{ display: showFactorSelector ? 'none' : 'block' }}>
                    <Form.Group controlId="changeMedication">
                        <Form.Label>Was there any change in medication?<span className="required">*</span></Form.Label>
                        <div className='inline-buttons'>
                            <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                name="changeMedication"
                                ref={register}
                                onChange={() => setShowMedicalHistory(true)}
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                name="changeMedication"
                                ref={register}
                                onChange={() => setShowMedicalHistory(false)}
                            />
                        </div>
                        {errors.changeMedication && (
                            <div className='error'>{errors.changeMedication.message}</div>
                        )}
                    </Form.Group>
                    {showMedicalHistory && <MedicationHistoryContainer patientId={patient.patientId} />}
                    <Form.Group controlId="lessMedication">
                        <Form.Label>Is there a reduction or elimination in medication from the beginning?<span className="required">*</span></Form.Label>
                        <div className="inline-buttons">
                            <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                name="lessMedication"
                                ref={register}
                                onChange={() => setShowDescribeMedicationChange(true)}
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                name="lessMedication"
                                ref={register}
                                onChange={() => setShowDescribeMedicationChange(false)}
                            />
                        </div>
                        {errors.lessMedication && (
                            <div className='error'>{errors.lessMedication.message}</div>
                        )}
                    </Form.Group>

                    {showDescribeMedicationChange &&
                        <Form.Group controlId="medicineChangeDetails">
                            <Form.Text className='mt-n3'>Please describe this change:<span className="required">*</span></Form.Text>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name='medicineChangeDetails'
                                ref={register}
                                isInvalid={errors.medicineChangeDetails}
                            />
                            {errors.medicineChangeDetails && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.medicineChangeDetails.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    }

                    <Form.Group controlId='overallProgramPercentage'>
                        <Form.Label>What percentage of the overall program are you doing?<span className="required">*</span></Form.Label>
                        <Form.Control
                            className='slider-blue'
                            type="range"
                            min="0"
                            max="100"
                            step="10"
                            defaultValue="0"
                            name='overallProgramPercentage'
                            ref={register}
                        />
                    </Form.Group>

                    <hr className='divider'></hr>

                    <div className="header">
                        <OnboardingStepHeader text="Primitive Reflex" />
                    </div>

                    <Form.Group controlId="primitiveReflex">
                        <Form.Label>Have you been doing the Primitive Reflex Exercises<span className="required">*</span></Form.Label>
                        <div className='inline-buttons'>
                            <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                name="primitiveReflex"
                                ref={register}
                                onChange={() => setShowPrimitiveReflexFrequency(true)}
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                name="primitiveReflex"
                                ref={register}
                                onChange={() => setShowPrimitiveReflexFrequency(false)}
                            />
                        </div>
                        {errors.primitiveReflex && (
                            <div className='error'>{errors.primitiveReflex.message}</div>
                        )}
                    </Form.Group>

                    {showPrimitiveReflexFrequency &&
                        <>
                            <Form.Label>What frequency do you do the exercise?<span className="required">*</span></Form.Label>
                            <Form.Group controlId="primitiveReflexDaily">
                                <Form.Text>How many times per day?</Form.Text>
                                <div className='inline-buttons'>
                                    {[1, 2, 3].map((option) => (
                                        <Form.Check
                                            key={`primitiveReflexDaily${option}`}
                                            type="radio"
                                            label={option}
                                            value={option}
                                            name="primitiveReflexDaily"
                                            ref={register}
                                        />
                                    ))}
                                    <>
                                        <Form.Check
                                            key='primitiveReflexDailyOther'
                                            type="radio"
                                            label="Other:"
                                            value={primitiveReflexDailyOtherCount}
                                            name="primitiveReflexDaily"
                                            ref={register}
                                        />
                                        <Form.Control
                                            className='other-input-button'
                                            type="number"
                                            onChange={(e) => { setPrimitiveReflexDailyOtherCount(e.target.value) }}
                                        />
                                    </>
                                </div>
                                {errors.primitiveReflexDaily && (
                                    <div className='error'>{errors.primitiveReflexDaily.message}</div>
                                )}
                                {errors.primitiveReflexDailyOther && (
                                    <div className='error'>{errors.primitiveReflexDailyOther.message}</div>
                                )}
                            </Form.Group>
                            <Form.Group controlId="primitiveReflexWeekly" className='mt-n3'>
                                <Form.Text>How many days per week?</Form.Text>
                                <div className='inline-buttons'>
                                    {[1, 2, 4, 5, 6, 7].map((option) => (
                                        <Form.Check
                                            key={`primitiveReflexWeekly${option}`}
                                            type="radio"
                                            label={option}
                                            value={option}
                                            name="primitiveReflexWeekly"
                                            ref={register}
                                        />
                                    ))}
                                </div>
                                {errors.primitiveReflexWeekly && (
                                    <div className='error'>{errors.primitiveReflexWeekly.message}</div>
                                )}
                            </Form.Group>
                        </>
                    }

                    <hr className='divider' />

                    <div className="header">
                        <OnboardingStepHeader text="Hemispheric Sensory" />
                    </div>

                    <Form.Group controlId="hemisphericSensory">
                        <Form.Label>Have you been doing Hemispheric Sensory Stimulation?<span className="required">*</span></Form.Label>
                        <div className='inline-buttons'>
                            <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                name="hemisphericSensory"
                                onChange={() => setShowHemisphericSensoryQuestions(true)}
                                ref={register}
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                name="hemisphericSensory"
                                onChange={() => setShowHemisphericSensoryQuestions(false)}
                                ref={register}
                            />
                        </div>
                        {errors.hemisphericSensory && (
                            <div className='error'>{errors.hemisphericSensory.message}</div>
                        )}
                    </Form.Group>

                    {showHemisphericSensoryQuestions &&
                        <>
                            <Form.Group controlId="hemisphericSensorySpecification">
                                <Form.Label>Please specify:<span className="required">*</span></Form.Label>
                                {hemisphericSensoryStimulationOptions.map((option) => (
                                    <Form.Check
                                        className='mb-2'
                                        key={`hemisphericSensory${option}`}
                                        type="checkbox"
                                        label={option}
                                        value={option}
                                        name='hemisphericSensorySpecification'
                                        ref={register}
                                    />
                                ))}
                                <div className='inline-buttons'>
                                    <Form.Check
                                        type="checkbox"
                                        label="Other:"
                                        value={hemisphericSensoryStimulationOther}
                                        name='hemisphericSensorySpecification'
                                        ref={register}
                                    />
                                    <Form.Control
                                        className='w-25 ml-n3'
                                        type="text"
                                        onChange={(e) => { setHemisphericSensoryStimulationOther(e.target.value) }}
                                    />
                                </div>
                                {errors.hemisphericSensorySpecification && (
                                    <div className='error'>
                                        {Array.isArray(errors.hemisphericSensorySpecification)
                                            ? errors.hemisphericSensorySpecification[0].message
                                            : errors.hemisphericSensorySpecification.message}
                                    </div>
                                )}
                            </Form.Group>

                            <Form.Label>What frequency do you do the exercise?<span className="required">*</span></Form.Label>
                            <Form.Group controlId="hemisphericSensoryDaily">
                                <Form.Text>How many times per day?</Form.Text>
                                <div className='inline-buttons'>
                                    {[1, 2, 3].map((option) => (
                                        <Form.Check
                                            key={`hemisphericSensoryDaily${option}`}
                                            type="radio"
                                            label={option}
                                            value={option}
                                            name="hemisphericSensoryDaily"
                                            ref={register}

                                        />
                                    ))}
                                    <>
                                        <Form.Check
                                            key='hemisphericSensoryDailyOther'
                                            type="radio"
                                            label="Other:"
                                            value={hemisphericSensoryDailyOtherCount}
                                            name="hemisphericSensoryDaily"
                                            ref={register}
                                        />
                                        <Form.Control
                                            className='other-input-button'
                                            type="number"
                                            onChange={(e) => { setHemisphericSensoryDailyOtherCount(e.target.value) }}
                                        />
                                    </>
                                </div>
                                {errors.hemisphericSensoryDaily && (
                                    <div className='error'>{errors.hemisphericSensoryDaily.message}</div>
                                )}
                            </Form.Group>
                            <Form.Group controlId="hemisphericSensoryWeekly" className='mt-n3'>
                                <Form.Text>How many days per week?</Form.Text>
                                <div className='inline-buttons'>
                                    {[1, 2, 3, 4, 5, 6, 7].map((option) => (
                                        <Form.Check
                                            key={option}
                                            type="radio"
                                            label={option}
                                            value={option}
                                            name="hemisphericSensoryWeekly"
                                            ref={register}
                                        />
                                    ))}
                                </div>
                                {errors.hemisphericSensoryWeekly && (
                                    <div className='error'>{errors.hemisphericSensoryWeekly.message}</div>
                                )}
                            </Form.Group>
                        </>
                    }

                    <hr className='divider' />

                    <Form.Group controlId="specialDiet">
                        <Form.Label>Have you been on a special diet?<span className="required">*</span></Form.Label>
                        <div className="inline-buttons">
                            <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                name="specialDiet"
                                onChange={() => setShowDescribeSpecialDiet(true)}
                                ref={register}
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                name="specialDiet"
                                onChange={() => setShowDescribeSpecialDiet(false)}
                                ref={register}
                            />
                        </div>
                        {errors.specialDiet && (
                            <div className='error'>{errors.specialDiet.message}</div>
                        )}
                    </Form.Group>
                    {showDescribeSpecialDiet &&
                        <Form.Group controlId="describeSpecialDiet">
                            <Form.Text className='mt-n3'>Please describe:<span className="required">*</span></Form.Text>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name='describeSpecialDiet'
                                ref={register}
                                isInvalid={errors.describeSpecialDiet}
                            />
                            {errors.describeSpecialDiet && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.describeSpecialDiet.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    }
                    <Form.Group controlId="vitamins">
                        <Form.Label>Have you been taking vitamins?<span className="required">*</span></Form.Label>
                        <div className="inline-buttons">
                            <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                name="vitamins"
                                ref={register}
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                name="vitamins"
                                ref={register}
                            />
                        </div>
                        {errors.vitamins && (
                            <div className='error'>{errors.vitamins.message}</div>
                        )}
                    </Form.Group>
                    <Form.Group controlId="functionLoss">
                        <Form.Label>Have you seen any regression or loss of function?<span className="required">*</span></Form.Label>
                        <div className="inline-buttons">
                            <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                name="functionLoss"
                                onChange={() => setShowDescribeFunctionLoss(true)}
                                ref={register}
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                name="functionLoss"
                                onChange={() => setShowDescribeFunctionLoss(false)}
                                ref={register}
                            />
                        </div>
                        {errors.functionLoss && (
                            <div className='error'>{errors.functionLoss.message}</div>
                        )}
                    </Form.Group>
                    {showDescribeFunctionLoss &&
                        <Form.Group controlId="describeFunctionLoss">
                            <Form.Text className='mt-n3'>Please describe:<span className="required">*</span></Form.Text>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name='describeFunctionLoss'
                                ref={register}
                                isInvalid={errors.describeFunctionLoss}
                            />
                            {errors.describeFunctionLoss && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.describeFunctionLoss.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    }
                    <Form.Group controlId="skills">
                        <Form.Label>Have you seen any improvements in or beginning to have new skills?<span className="required">*</span></Form.Label>
                        <div className="inline-buttons">
                            <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                name="skills"
                                onChange={() => setShowDescribeSkills(true)}
                                ref={register}
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                name="skills"
                                onChange={() => setShowDescribeSkills(false)}
                                ref={register}
                            />
                        </div>
                        {errors.skills && (
                            <div className='error'>{errors.skills.message}</div>
                        )}
                    </Form.Group>
                    {showDescribeSkills &&
                        <Form.Group controlId="describeSkills">
                            <Form.Text className='mt-n3'>Please describe:<span className="required">*</span></Form.Text>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name='describeSkills'
                                ref={register}
                                isInvalid={errors.describeSkills}
                            />
                            {errors.describeSkills && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.describeSkills.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    }
                    <Form.Group controlId='nonVerbalCommunication'>
                        <Form.Label>What percentage improvement in nonverbal communication have you seen?<span className="required">*</span></Form.Label>
                        <Form.Control
                            className='slider-blue'
                            type="range"
                            min="0"
                            max="100"
                            step="10"
                            defaultValue="0"
                            name='nonVerbalCommunication'
                            ref={register}
                        />
                        {errors.nonVerbalCommunication && (
                            <div className='error'>{errors.nonVerbalCommunication.message}</div>
                        )}
                    </Form.Group>
                    <Form.Group controlId='verbalCommunication'>
                        <Form.Label>What percentage improvement in verbal communication have you seen?<span className="required">*</span></Form.Label>
                        <Form.Control
                            className='slider-blue'
                            type="range"
                            min="0"
                            max="100"
                            step="10"
                            defaultValue="0"
                            name='verbalCommunication'
                            ref={register}
                        />
                        {errors.verbalCommunication && (
                            <div className='error'>{errors.verbalCommunication.message}</div>
                        )}
                    </Form.Group>
                    <Form.Group controlId='academicSkills'>
                        <Form.Label>What percentage improvement in academic skills have you seen?<span className="required">*</span></Form.Label>
                        <Form.Control
                            className='slider-blue'
                            type="range"
                            min="0"
                            max="100"
                            step="10"
                            defaultValue="0"
                            name='academicSkills'
                            ref={register}
                        />
                        {errors.academicSkills && (
                            <div className='error'>{errors.academicSkills.message}</div>
                        )}
                    </Form.Group>
                    <Form.Group controlId='behavior'>
                        <Form.Label>What percentage improvement in behavior have you seen?<span className="required">*</span></Form.Label>
                        <Form.Control
                            className='slider-blue'
                            type="range"
                            min="0"
                            max="100"
                            step="10"
                            defaultValue="0"
                            name='behavior'
                            ref={register}
                        />
                        {errors.behavior && (
                            <div className='error'>{errors.behavior.message}</div>
                        )}
                    </Form.Group>
                    <Form.Group controlId='physicalChange'>
                        <Form.Label>What percentage improvement in physical changes have you seen?<span className="required">*</span></Form.Label>
                        <Form.Control
                            className='slider-blue'
                            type="range"
                            min="0"
                            max="100"
                            step="10"
                            defaultValue="0"
                            name='physicalChange'
                            ref={register}
                        />
                        {errors.physicalChange && (
                            <div className='error'>{errors.physicalChange.message}</div>
                        )}
                    </Form.Group>
                    <Form.Group controlId='overallProgress'>
                        <Form.Label>What percentage improvement in overall progress have you seen?<span className="required">*</span></Form.Label>
                        <Form.Control
                            className='slider-blue'
                            type="range"
                            min="0"
                            max="100"
                            step="10"
                            defaultValue="0"
                            name='overallProgress'
                            ref={register}
                        />
                        {errors.overallProgress && (
                            <div className='error'>{errors.overallProgress.message}</div>
                        )}
                    </Form.Group>
                    <Form.Group controlId="additionalComments">
                        <Form.Label>Please express any additional comments</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            ref={register}
                            name='additionalComments'
                        />
                    </Form.Group>
                    <Form.Group controlId="followUpAppointment">
                        <Form.Label>Do you have a follow up appointment scheduled?<span className="required">*</span></Form.Label>
                        <div className='inline-buttons'>
                            <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                name="followUpAppointment"
                                ref={register}
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                name="followUpAppointment"
                                ref={register}
                            />
                        </div>
                        {errors.followUpAppointment && (
                            <div className='error'>{errors.followUpAppointment.message}</div>
                        )}
                    </Form.Group>
                    <Button variant="outline-primary" type='submit'>
                        Save & Continue &gt;
                    </Button>
                </div>
            </Form>
            <div className='factorSelectorContainer' style={{ display: showFactorSelector ? 'block' : 'none' }}>
                <FactorSelector1 />
                <div className='buttons mt-3'>
                    {/* <Button variant="outline-primary" type='button' onClick={handleBack}>
                            &lt; Back
                        </Button> */}
                    {/* <Button variant="outline-primary" type='submit'>
                            Submit &gt;
                        </Button> */}
                </div>
            </div>
        </div >
    )
}

export default FollowUpForm;