import axios from "axios";
import apiUrl from "./apiConfig";

axios.defaults.withCredentials = true;

export const createCondition = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/condition/`, data, {
    params: { patientId },
  });
};

export const createTBI = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/tbi-incident/`, data, {
    params: { patientId },
  });
};

export const createStroke = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/stroke-incident1/`, data, {
    params: { patientId },
  });
};

export const createFamilyHistory = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/create-family-history`, data, {
    params: { patientId },
  });
};

export const createHistoryMeds = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/history-meds`, data, {
    params: { patientId },
  });
};

export const getHistoryMeds = ({ patientId }) => {
  return axios.get(`${apiUrl}/record-builder/get-history-meds`, {
    params: { patientId },
  });
};

export const updateHistoryMeds = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/update-history-meds`, data, {
    params: { patientId },
  });
};

export const submitFollowUpForm = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/submit-follow-up-form`, data, {
    params: { patientId },
  });
};

export const submitFollowUpFactors = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/submit-follow-up-factors`, data, {
    params: { patientId },
  });
};

export const updateTBIDetail = ({ data, patientId }) => {
  return axios.put(`${apiUrl}/record-builder/tbi-detail`, data, {
    params: { patientId },
  });
};

export const updateStrokeDetail = ({ data, patientId }) => {
  return axios.put(`${apiUrl}/record-builder/stroke-detail`, data, {
    params: { patientId },
  });
};

export const getTherapiesList = () => {
  return axios(`${apiUrl}/record-builder/available-therapies/`);
};

export const getTBISymptoms = () => {
  return axios(`${apiUrl}/record-builder/available-symptoms/`);
};

export const getTBIFormData = () => {
  return axios(`${apiUrl}/record-builder/tbi-form-fill/`);
};

export const getConditionFormData = () => {
  return axios(`${apiUrl}/record-builder/condition-form-fill/`);
};

export const postTrackingPrefs = ({ data }) => {
  return axios.post(
    `${apiUrl}/record-builder/symptom-tracking-preferences`,
    data
  );
};

//this route was combined from previously (postTopSymptoms, postTopSDOH)
export const postTrackedFactors = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/add-tracked-factors`, data, {
    params: { patientId },
  });
};

export const postMoreTrackedFactors = ({ data, patientId }) => {
  return axios.post(
    `${apiUrl}/record-builder/add-dashboard-tracked-factors`,
    data,
    {
      params: { patientId },
    }
  );
};

// Those users chose in the first place when registering
export const postWorstSymptomsFactors = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/first-tracked-factors`, data, {
    params: { patientId },
  });
};

// Those users chose in the first place when registering
export const postRegisteredSdoh = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/registered-sdoh`, data, {
    params: { patientId },
  });
};
// export const postResultingFactors = ({ data, patientId }) => {
//   return axios.post(`${apiUrl}/record-builder/add-resulting-factors`, data, {
//     params: { patientId },
//   });
// };

export const postRegisterFactors = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/registered-factors`, data, {
    params: { patientId },
  });
};

export const postNewResultingFactors = ({ data, patientId }) => {
  return axios.post(
    `${apiUrl}/record-builder/add-new-resulting-factors`,
    data,
    {
      params: { patientId },
    }
  );
};

export const postPreviousTherapies = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/add-previous-therapies`, data, {
    params: { patientId },
  });
};

export const checkFeelingExisting = (date) => {
  return axios(`${apiUrl}/dashboard/checkFeelingExisting`, {
    params: { date },
  });
};
// export const getUserId = (patientId) => {
//   return axios(`${apiUrl}/getUserId`, { params: { patientId } });
// };

export const getTbiDate = (patientId) => {
  return axios(`${apiUrl}/getTbiDate`, { params: { patientId } });
};
export const getNonTbiDate = (patientId) => {
  return axios(`${apiUrl}/getNonTbiDate`, { params: { patientId } });
};

export const getStrokeDate = (patientId) => {
  return axios(`${apiUrl}/getStrokeDate`, { params: { patientId } });
};

export const postFeelingToday = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/dashboard/feeling-today`, data, {
    params: { patientId },
  });
};

export const getTopFactors = (patientId) => {
  return axios(`${apiUrl}/dashboard/get-users-top-factors`, {
    params: { patientId },
  });
};

export const getTrackedData = ({ patientId, userId }) => {
  return axios(`${apiUrl}/dashboard/get-last-tracked-factors`, {
    params: { patientId, userId },
  });
};

export const getAnotherTPOrPatientTrackedData = ({
  patientId,
  userId,
  curProviderId,
  selectedId,
}) => {
  return axios(`${apiUrl}/dashboard/getAnotherTPOrPatientTrackedData`, {
    params: { patientId, userId, curProviderId, selectedId },
  });
};

export const getConnectedPatientOfTPData = ({ patientId }) => {
  return axios(`${apiUrl}/dashboard/getConnectedPatientOfTPData`, {
    params: { patientId },
  });
};

export const getLastTrackedFeelingToday = (patientId) => {
  return axios(`${apiUrl}/dashboard/last-tracked-feeling-today`, {
    params: { patientId },
  });
};

export const postTrackSymptom = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/dashboard/symptom-tracking/`, data, {
    params: { patientId },
  });
};

export const getFirstTrackedData = (patientId) => {
  return axios(`${apiUrl}/dashboard/get-first-tracked-factors`, {
    params: { patientId },
  });
};

export const getSymptomsCategories = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/dashboard/get-symptoms-categories`, data, {
    params: { patientId },
  });
};

export const removePausedFactor = ({ data, patientId }) => {
  return axios.delete(`${apiUrl}/dashboard/remove-paused-factor`, {
    data: { data, params: { patientId } },
  });
};

export const removeTrackedFactors = ({ data, patientId }) => {
  return axios.delete(`${apiUrl}/dashboard/remove-tracked-factors`, {
    data: { data, params: { patientId } },
  });
};

export const addTrackedFactorsUnpaused = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/dashboard/add-tracked-factors-unpaused`, data, {
    params: { patientId },
  });
};

export const getPausedList = (patientId) => {
  return axios(`${apiUrl}/dashboard/get-paused-list`, {
    params: { patientId },
  });
};

export const postPausedList = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/dashboard/post-paused-list`, data, {
    params: { patientId },
  });
};

export const postAdditionalNote = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/dashboard/post-additional-note`, data, {
    params: { patientId },
  });
};

export const getAdditionalNote = (patientId) => {
  return axios.get(`${apiUrl}/dashboard/get-additional-note`, {
    params: { patientId },
  });
};

export const getPatientRegistrationProgress = (patientId) => {
  return axios.get(`${apiUrl}/dashboard/getPatientRegistrationProgress`, {
    params: { patientId },
  });
};

export const updateGoals = ({ data, patientId }) => {
  return axios.put(`${apiUrl}/record-builder/update-goals`, data, {
    params: { patientId },
  });
};

export const updateDarkModePref = (data) => {
  return axios.put(`${apiUrl}/user/update-darkmode-preferences`, data);
};

export const getDarkModePref = (userId) => {
  return axios(`${apiUrl}/dashboard/get-darkmode-preferences`, {
    params: { userId },
  });
};

export const updateLanguagePref = (data) => {
  return axios.put(`${apiUrl}/user/update-language-preferences`, data);
};

export const getLanguagePref = (userId) => {
  return axios(`${apiUrl}/user/get-language-preferences`, {
    params: { userId },
  });
};

export const updateReceivingPatientAlert = (data) => {
  return axios.put(`${apiUrl}/user/updateReceivingPatientAlert`, data);
};

export const getReceivingPatientAlertStatus = (userId) => {
  return axios(`${apiUrl}/dashboard/getReceivingPatientAlertStatus`, {
    params: { userId },
  });
};

export const updateReceivingPatientMessages = (data) => {
  return axios.put(`${apiUrl}/user/updateReceivingPatientMessages`, data);
};

export const getReceivingPatientMessagesStatus = (userId) => {
  return axios(`${apiUrl}/dashboard/getReceivingPatientMessagesStatus`, {
    params: { userId },
  });
};

export const updatePatientStatus = (data) => {
  return axios.put(`${apiUrl}/dashboard/updatePatientStatus`, data);
};

export const getPatientStatus = ({ userType, patientId }) => {
  return axios(`${apiUrl}/dashboard/getPatientStatus`, {
    params: { patientId, userType },
  });
};

export const getGoals = (patientId) => {
  return axios(`${apiUrl}/dashboard/get-goals`, { params: { patientId } });
};

export const getDaysFromInjury = (patientId) => {
  return axios(`${apiUrl}/dashboard/getDaysFromInjury`, {
    params: { patientId },
  });
};

export const getTBIIncidents = (patientId) => {
  return axios(`${apiUrl}/tbi-incidents`, {
    params: { patientId },
  });
};

export const createGoals = ({ patientId }) => {
  return axios.post(
    `${apiUrl}/record-builder/create-goals`,
    {},
    { params: { patientId } }
  );
};

export const addSymptomDetails = ({ data, patientId }) => {
  return axios.post(`${apiUrl}/record-builder/add-symptom-details`, data, {
    params: { patientId },
  });
};

export const getPatientTypes = () => {
  return axios(`${apiUrl}/record-builder/get-patient-types`);
};

export const getReferralGroups = () => {
  return axios(`${apiUrl}/record-builder/get-referral-groups`);
};

export const getProviderTypes = () => {
  return axios(`${apiUrl}/getProviderTypes`);
};
